<template>
    <svg
        class="stroke-current"
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.5 2.25L10.25 11L1.5 19.75"
            stroke-width="2.625"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
